import "./index.css";

const TopTripsEmptyPlaceholder = () => {
    return (
        <div className='placeholder'>
            Пока нет ни одного путешествия
        </div>
    );
};

export default TopTripsEmptyPlaceholder;