export const BASE_URL = "https://api.triptip.pro"

export const URL = {
  TRIPS: `${BASE_URL}/trips`,
  GET_USER: `${BASE_URL}/users`,
};

export const ERROR_MESSAGES = {
  NETWORK_ERROR: "Network response was not ok.",
  USERPIC_FETCH_FAILED: "Failed to fetch userpic",
  USER_FETCH_FAILED: "Failed to fetch user data.",
  TRIP_FETCH_FAILED: "Failed to fetch trips data.",
};

export const LOADING_MESSAGE = "Одну секунду, загружаю"

export const DISPLAY_LIMIT = 5;